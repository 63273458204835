/* roboto-100normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin '),
    local('Roboto-Thin'),
    url(/static/media/roboto-latin-100.7370c367.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100.5cb7edfc.woff) format('woff'); /* Modern Browsers */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin italic'),
    local('Roboto-Thinitalic'),
    url(/static/media/roboto-latin-100italic.f8b1df51.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100italic.f9e8e590.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light '),
    local('Roboto-Light'),
    url(/static/media/roboto-latin-300.ef7c6637.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300.b00849e0.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light italic'),
    local('Roboto-Lightitalic'),
    url(/static/media/roboto-latin-300italic.14286f3b.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300italic.4df32891.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular '),
    local('Roboto-Regular'),
    url(/static/media/roboto-latin-400.479970ff.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400.60fa3c06.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular italic'),
    local('Roboto-Regularitalic'),
    url(/static/media/roboto-latin-400italic.51521a2a.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400italic.fe65b833.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium '),
    local('Roboto-Medium'),
    url(/static/media/roboto-latin-500.020c97dc.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500.87284894.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium italic'),
    local('Roboto-Mediumitalic'),
    url(/static/media/roboto-latin-500italic.db4a2a23.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500italic.288ad9c6.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold '),
    local('Roboto-Bold'),
    url(/static/media/roboto-latin-700.2735a3a6.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700.adcde98f.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold italic'),
    local('Roboto-Bolditalic'),
    url(/static/media/roboto-latin-700italic.da0e7178.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700italic.81f57861.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black '),
    local('Roboto-Black'),
    url(/static/media/roboto-latin-900.9b3766ef.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900.bb1e4dc6.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black italic'),
    local('Roboto-Blackitalic'),
    url(/static/media/roboto-latin-900italic.ebf6d164.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900italic.28f91510.woff) format('woff'); /* Modern Browsers */
}


